import React, { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import Button from "@atoms/button"
import CardTestimonial from "@molecules/cards/testimonial"
import { media } from "@styles/breakpoints"
import { gsap } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query fetchTestimonialCards {
      datoCmsHome {
        proofHeading
        testimonials: testimonialSlider {
          excerpt
          company
          industry
          post {
            slug
          }
          intervieweeName
          customImage {
            alt
            title
            gatsbyImageData(
              width: 160
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false

              imgixParams: {
                fm: "webp, png"
                crop: "faces"
                auto: "compress"
                q: 90
              }
            )
          }
        }
        brands: logos {
          alt
          title
          gatsbyImageData(
            width: 320
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
      }
    }
  `)

  const { testimonials, brands } = data.datoCmsHome

  const [activeTestimonial, setActiveTestimonial] = useState(0)
  const [clicked, setClicked] = useState()
  const [bulletTL] = useState(gsap.timeline())

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(DrawSVGPlugin)
    }
    gsap.set(".circle", {
      drawSVG: "0% 0%",
      transformOrigin: "50% 50%",
      rotation: -90,
    })

    return () => {
      bulletTL.kill()
    }
  }, [bulletTL])

  const chooseTestimonial = (index) => {
    setClicked(true)
    bulletTL.seek(5).clear()
    setActiveTestimonial(index, () => {
      bulletTL.seek(0.8)
    })
  }

  const rotateActiveTestimonial = useCallback(() => {
    if (activeTestimonial >= testimonials.length - 1) {
      setActiveTestimonial(0)
    } else {
      setActiveTestimonial(activeTestimonial + 1)
    }
  }, [setActiveTestimonial, activeTestimonial, testimonials.length])

  useEffect(() => {
    const circle = document.querySelector(".bulletActive .circle")

    if (clicked) {
      bulletTL
        .addLabel("progress")
        .fromTo(
          circle,
          { drawSVG: "0% 100%", duration: 4.2 },
          { drawSVG: "100% 100%", duration: 4.2 },
          "progress"
        )
        .eventCallback("onComplete", () => rotateActiveTestimonial())

      setClicked(false)
    } else {
      bulletTL
        .addLabel("start")
        .fromTo(
          circle,
          { drawSVG: "0% 0%", duration: 0.8 },
          { drawSVG: "0% 100%", duration: 0.8 },
          "start"
        )
        .addLabel("progress")
        .fromTo(
          circle,
          { drawSVG: "0% 100%", duration: 4.2 },
          { drawSVG: "100% 100%", duration: 4.2 },
          "progress"
        )
        .eventCallback("onComplete", () => rotateActiveTestimonial())
    }
  }, [activeTestimonial, bulletTL, rotateActiveTestimonial])

  const hoverOnBullet = (e) => {
    const circle = e.target.querySelector(".circle")
    gsap.fromTo(
      circle,
      { drawSVG: "0% 0%", duration: 0.8 },
      { drawSVG: "0% 100%", duration: 0.8 }
    )
  }

  const hoverOffBullet = (e) => {
    const circle = e.target.querySelector(".circle")
    gsap.fromTo(
      circle,
      { drawSVG: "0% 100%", duration: 0.8 },
      { drawSVG: "100% 100%", duration: 0.8 }
    )
  }

  return (
    <SectionTestimonials light>
      <Container>
        <Flex alignCenter>
          <Column half>
            <Carousel
              onMouseEnter={() => {
                bulletTL.paused(true)
              }}
              onMouseLeave={() => {
                bulletTL.paused(false)
              }}
            >
              <Cards>
                {testimonials.map((testimonial, index) => {
                  let distance = index - activeTestimonial
                  let testimonialPosition
                  if (distance < 0) {
                    testimonialPosition = testimonials.length + distance
                  } else {
                    testimonialPosition = distance
                  }
                  return (
                    <Testimonial
                      key={index}
                      className={`testimonial testimonial-${testimonialPosition} ${
                        index === activeTestimonial ? "-cardActive" : ""
                      }`}
                    >
                      <CardTestimonial testimonial={testimonial} />
                    </Testimonial>
                  )
                })}
              </Cards>
              <Navigation>
                {testimonials.map((testimonial, index) => {
                  return (
                    <Bullet
                      key={index}
                      onClick={() => chooseTestimonial(index)}
                      className={
                        index === activeTestimonial
                          ? "bulletActive -active"
                          : ""
                      }
                      onMouseEnter={(e) => {
                        index !== activeTestimonial && hoverOnBullet(e)
                      }}
                      onMouseLeave={(e) => {
                        index !== activeTestimonial && hoverOffBullet(e)
                      }}
                    >
                      <Status
                        viewBox="0 0 100 100"
                        className={index === activeTestimonial ? "-active" : ""}
                      >
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          fill="none"
                          strokeWidth="5"
                          className="circle"
                        />
                      </Status>
                    </Bullet>
                  )
                })}
              </Navigation>
            </Carousel>
          </Column>
          <Column half>
            <Content>
              <Heading html="h6" level="sh" strong lightGray>
                Proof
              </Heading>
              <Heading html="h2" level="h2">
                {data.datoCmsHome.proofHeading}
              </Heading>
              <Brands>
                {brands.map((brand, index) => (
                  <Brand key={index}>
                    <GatsbyImage
                      image={brand.gatsbyImageData}
                      alt={brand.alt}
                    />
                  </Brand>
                ))}
              </Brands>
              <Button primary border to="/testimonials">
                Read More Success Stories
              </Button>
            </Content>
          </Column>
        </Flex>
      </Container>
    </SectionTestimonials>
  )
}

export default Testimonials

const SectionTestimonials = styled(Section)`
  padding: 0;
  padding-top: var(--spacing--md);
  padding-bottom: var(--spacing--md);

  ${media.sm`
    padding: var(--spacingSection);
    padding-top: var(--spacing--lg);
    padding-bottom: var(--spacing--lg);
  `}
`

const Content = styled.div`
  padding: var(--spacingSection);

  ${media.sm`
    padding: 0;
  `}
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
`

const Cards = styled.ul`
  height: calc(480px + 2rem);
  margin: 0 auto;
  position: relative;
  transform: translateX(-1rem);
  width: 100%;

  ${media.xs`
    height: calc(440px + 2rem);
  `}

  ${media.sm`
    height: calc(520px + 2rem);
  `}

  ${media.md`
    width: 90%;
  `}
`

const Testimonial = styled.li`
  margin: 0 var(--spacing--xs);
  position: absolute;
  top: 0;
  transition: var(--transitionBase);
  transform-origin: center bottom;

  &:after {
    background: linear-gradient(
      180.18deg,
      rgba(248, 248, 251, 0) 0.77%,
      rgba(248, 248, 251, 1) 94.61%
    );
    bottom: -2.5rem;
    content: "";
    height: 65%;
    left: -10%;
    position: absolute;
    transition: var(--transitionShort);
    width: 120%;
    z-index: var(--zNav);
  }

  &.testimonial-0 {
    opacity: 1;
    transform: translate(0rem, 0rem);
    z-index: 5;
  }
  &.testimonial-1 {
    opacity: 0.9;
    transform: translate(0.5rem, 0.5rem);
    z-index: 4;
  }
  &.testimonial-2 {
    opacity: 0.8;
    transform: translate(1rem, 1rem);
    z-index: 3;
  }
  &.testimonial-3 {
    opacity: 0.7;
    transform: translate(1.5rem, 1.5rem);
    z-index: 2;
  }
  &.testimonial-4 {
    opacity: 0.6;
    transform: translate(2rem, 2rem);
    z-index: 1;
  }
`
const Navigation = styled.ul`
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadowShallow);
  bottom: 0;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  transform: translateX(-2rem);
  width: 160px;
  z-index: 6;
`

const Bullet = styled.li`
  background-color: var(--gray--40);
  border-radius: 1rem;
  height: 0.5rem;
  margin: 0.5rem;
  position: relative;
  width: 0.5rem;

  &.-active {
    background-color: var(--colorPrimary);
  }
`

const Status = styled.svg`
  height: 1rem;
  left: -0.25rem;
  position: absolute;
  stroke: var(--gray--40);
  top: -0.25rem;
  width: 1rem;

  &.-active {
    stroke: var(--colorPrimary);
  }
`

const Brands = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: var(--spacing--sm);
  transform: translateX(-0.5rem);

  ${media.sm`
    transform: translateX(-1rem);
  `}
`

const Brand = styled.div`
  align-items: center;
  flex-basis: calc(33.3% - 0.5rem);
  flex-shrink: 0;
  margin: 0 0.25rem;
  width: calc(33.3% - 0.5rem);

  ${media.sm`
    flex-basis: 180px;
    margin: 0 0.5rem;
    width: 160px;
  `}
`

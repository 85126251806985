import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "@utils/layout"
import { media } from "@styles/breakpoints"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"

import SignUp from "@molecules/signUp"
import PapercraftHome from "@molecules/papercraft/home"
import SectionTestimonials from "@organisms/sections/testimonials"
import SectionExtensions from "@organisms/sections/extensions"

import BlockAutomation from "@molecules/homeBlocks/blockAutomation"
import BlockBackOffice from "@molecules/homeBlocks/blockBackOffice"
import BlockBilling from "@molecules/homeBlocks/blockBilling"
import BlockCheckouts from "@molecules/homeBlocks/blockCheckouts"
import BlockRecapture from "@molecules/homeBlocks/blockRecapture"
import BlockSecurity from "@molecules/featureBlocks/blockSecurity"

const IndexPage = ({ data }) => {
  const scrollCircle = useRef()
  const scrollTopArrow = useRef()
  const scrollBottomArrow = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.registerPlugin(DrawSVGPlugin)
    }

    gsap.defaults({
      ease: "sine.inOut",
    })

    gsap.set(scrollCircle.current, {
      drawSVG: "0% 0%",
      transformOrigin: "50% 50%",
      rotation: -90,
    })

    gsap.set(scrollTopArrow.current, {
      y: "-1.25rem",
      opacity: 0,
    })
    gsap.set(scrollBottomArrow.current, { y: 0, opacity: 0 })

    const tl = gsap.timeline({ delay: 1 })

    tl.fromTo(
      scrollCircle.current,
      { drawSVG: "0% 0%", duration: 1 },
      { drawSVG: "0% 100%", duration: 1 }
    )
    tl.to(scrollBottomArrow.current, {
      opacity: 1,
      duration: 0.5,
    })
    tl.to(scrollBottomArrow.current, {
      y: "1.25rem",
      delay: 0.5,
      opacity: 0,
      duration: 0.25,
    })
    tl.to(scrollTopArrow.current, {
      y: 0,
      opacity: 1,
      duration: 0.25,
    })

    ScrollTrigger.matchMedia({
      "(min-width: 800px)": function () {
        ScrollTrigger.create({
          id: "homeNavigation",
          trigger: ".homeHeader",
          start: "top center",
          end: "bottom top-=-720px",
          toggleClass: {
            targets: ".navigation",
            className: "-active",
          },
        })
      },
    })

    return () => {
      ScrollTrigger.matchMedia({
        "(min-width: 800px)": function () {
          ScrollTrigger.getById("homeNavigation").disable()
        },
      })
    }
  }, [])

  return (
    <Layout route="home">
      <HelmetDatoCms seo={data.datoCmsHome.seoMetaTags} />
      <div data-datocms-noindex>
        <HeaderSection className="homeHeader" smTop full>
          <HeaderContainer>
            <Flex twoCol alignCenter>
              <HeaderColumn half>
                <HomeHeading html="h1" level="h1">
                  {data.datoCmsHome.heading}
                </HomeHeading>
                <Field>
                  <SignUp id={1} />
                </Field>
                <HomeParagraph level="md" noMargin center>
                  {data.datoCmsHome.subheading}
                </HomeParagraph>
              </HeaderColumn>
            </Flex>
            <Scroll>
              <Arrow
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="4"
                fill="none"
                viewBox="0 0 8 4"
                ref={scrollTopArrow}
              >
                <path fill="#A1A6A9" d="M.625.5L4 3.875 7.375.5H.625z"></path>
              </Arrow>
              <Arrow
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="4"
                fill="none"
                viewBox="0 0 8 4"
                ref={scrollBottomArrow}
              >
                <path fill="#A1A6A9" d="M.625.5L4 3.875 7.375.5H.625z"></path>
              </Arrow>
              <Circle viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  strokeWidth="3"
                  ref={scrollCircle}
                />
              </Circle>
            </Scroll>
          </HeaderContainer>
          <Image>
            <PapercraftHome />
          </Image>
        </HeaderSection>
        <SectionTestimonials />
        <Section>
          {data.datoCmsHome.content.map((block, index) => (
            <React.Fragment key={index}>
              {block.model.apiKey === "block_automation" ? (
                <BlockAutomation block={block} />
              ) : block.model.apiKey === "block_back_office" ? (
                <BlockBackOffice block={block} />
              ) : block.model.apiKey === "block_billing" ? (
                <BlockBilling block={block} />
              ) : block.model.apiKey === "block_checkout" ? (
                <BlockCheckouts block={block} />
              ) : block.model.apiKey === "block_recapture" ? (
                <BlockRecapture block={block} />
              ) : block.model.apiKey === "block_security" ? (
                <BlockSecurity block={block} />
              ) : (
                <h1>No Corrosponding Block</h1>
              )}
            </React.Fragment>
          ))}
        </Section>
        <SectionExtensions />
      </div>
    </Layout>
  )
}

export const IndexPageQuery = graphql`
  {
    datoCmsHome {
      heading
      subheading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ...BlockAutomation
        ...BlockBackOffice
        ...BlockBilling
        ...BlockCheckout
        ...BlockRecapture
        ...BlockSecurity
      }
    }
  }
`

export default IndexPage

const HomeHeading = styled(Heading)`
  @media (max-width: 560px) {
    font-size: 1.75rem;
    text-align: center;
  }
`

const HomeParagraph = styled(Paragraph)`
  @media (max-width: 560px) {
    max-width: 320px;
    margin: 0 auto !important;
  }
`

const HeaderSection = styled(Section)`
  overflow: hidden;
  position: relative;
`

const HeaderContainer = styled(Container)`
  padding: var(--spacingSection);
  padding-bottom: var(--spacing--sm);
  padding-top: var(--spacing--lg);
  max-width: 98rem;

  ${media.sm`
    padding-bottom: var(--spacing--xl);
    padding-top: var(--spacing--xl);
  `}
`

const HeaderColumn = styled(Column)`
  ${media.sm`
    max-width: 580px;
  `}
`

const Field = styled.div`
  margin-bottom: var(--spacing--xxs);
`

const Image = styled.div`
  align-items: center;
  background: linear-gradient(145.1deg, #23384a 0%, #0d1d2b 92.51%);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: -1;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
    width: 100%;
  }

  ${media.sm`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50vw;

     &:after {
       display: none;
     }
  `}
`

const Scroll = styled.div`
  align-items: center;
  bottom: 2rem;
  display: none;
  justify-content: center;
  position: absolute;
  overflow: hidden;

  ${media.sm`
    display: flex;
    bottom: 4rem;
    left: 2rem;
  `}

  ${media.md`
    left: 4rem;
  `}

  ${media.lg`
    left: 5rem;
  `}
`

const Circle = styled.svg`
  height: 2rem;
  stroke: var(--gray--50);
  width: 2rem;
`

const Arrow = styled.svg`
  position: absolute;

  path {
    fill: var(--gray--50);
  }
`

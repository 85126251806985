import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Heading, Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"

const CardTestimonial = ({ testimonial }) => {
  return (
    <Card>
      <Quote>
        <Paragraph level="lg">{testimonial.excerpt}</Paragraph>
      </Quote>
      <Info>
        <Avatar>
          <GatsbyImage
            image={testimonial.customImage.gatsbyImageData}
            alt={testimonial.customImage.alt}
          />
        </Avatar>
        <Content>
          <Heading html="h5" level="h5">
            {testimonial.intervieweeName}
          </Heading>
          <Heading html="h6" level="sh" strong gray>
            {testimonial.company}
          </Heading>
        </Content>
      </Info>
    </Card>
  )
}

export default CardTestimonial

const Card = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 8px 40px 0 rgba(210, 212, 227, 0.4);
  display: flex;
  height: 480px;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 3rem var(--spacing--xs);
  position: relative;
  transition: var(--transitionShort);

  ${media.xs`
    height: 440px;
  `}

  ${media.sm`
    height: 520px;
  `}

  &:before {
    color: var(--colorPrimary);
    content: "“";
    font-size: 4.5rem;
    left: 2.5rem;
    position: absolute;
    top: 1.5rem;
    z-index: var(--zNav);
  }
`

const Quote = styled.div`
  padding-top: var(--spacing--sm);
  z-index: var(--zModal);
`

const Info = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: var(--zModal);
  padding-bottom: var(--spacing--xs);
`

const Avatar = styled.div`
  border-radius: 40px;
  flex-basis: auto;
  height: 48px;
  margin-right: 1rem;
  overflow: hidden;
  width: 48px;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Content = styled.div`
  h5 {
    margin-bottom: 0.5rem;
  }
  h6 {
    margin: 0;
  }
`

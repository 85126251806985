import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import styled from "styled-components"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { PapercraftImages, PapercraftImage } from "@atoms/papercraft"
import { media } from "@styles/breakpoints"

const PapercraftHome = () => {
  const images = useRef()
  const person = useRef()
  const personShadow = useRef()
  const phone = useRef()
  const phoneShadow = useRef()
  const card = useRef()
  const cardShadow = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.defaults({
      ease: "sine.inOut",
    })

    const tl = gsap.timeline({ repeat: -1, yoyo: true })

    tl.addLabel("start")
    tl.to(
      person.current,
      {
        y: "-=2%",
        duration: 4,
      },
      "start"
    )
    tl.to(
      personShadow.current,
      {
        opacity: "-=0.5",
        duration: 4,
      },
      "start"
    )
    tl.to(
      phone.current,
      {
        y: "-=4%",
        duration: 4,
      },
      "start"
    )
    tl.to(
      phoneShadow.current,
      {
        opacity: "-=0.35",
        duration: 4,
      },
      "start"
    )
    tl.to(
      card.current,
      {
        y: "-=3%",
        duration: 4,
      },
      "start"
    )
    tl.to(
      cardShadow.current,
      {
        opacity: "-=0.35",
        duration: 4,
      },
      "start"
    )

    ScrollTrigger.create({
      trigger: images.current,
      onEnter: () => {
        tl.play()
      },
      onEnterBack: () => {
        tl.play()
      },
      onLeave: () => {
        tl.pause()
      },
      onLeaveBack: () => {
        tl.pause()
      },
    })
  }, [])

  const width = 800
  const quality = 90

  return (
    <HomeImages ref={images}>
      <PapercraftImage fullWidth ref={person}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/person.png"
          alt="Papercraft man reaching out to complete MoonClerk checkout"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage fullWidth ref={personShadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/person_shadow.png"
          alt="Shadow of papercraft man"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage fullWidth ref={phone}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/phone.png"
          alt="Papercraft phone with MoonClerk checkout"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage fullWidth ref={phoneShadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/phone_shadow.png"
          alt="Shadow of papercraft phone"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage fullWidth ref={card}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/card.png"
          alt="Papercraft credit card used to checkout on phone"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
      <PapercraftImage fullWidth ref={cardShadow}>
        <StaticImage
          src="../../../assets/visuals/papercraft/home/card_shadow.png"
          alt="Shadow of papercraft phone"
          width={width}
          quality={quality}
          loading="eager"
          placeholder="none"
        />
      </PapercraftImage>
    </HomeImages>
  )
}

export default PapercraftHome

const HomeImages = styled(PapercraftImages)`
  max-width: 50rem;
  position: absolute;

  .gatsby-image-wrapper {
    overflow: visible !important;
  }

  ${media.sm`
    position: relative;
  `}
`

import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column, Content } from "@atoms/layout"
import Button from "@atoms/button"
import PapercraftBilling from "@molecules/papercraft/billing"

const Billing = ({ block }) => {
  return (
    <Section lgTop>
      <Container>
        <Flex alignCenter rowReverse>
          <ColumnImage half>
            <PapercraftBilling />
          </ColumnImage>
          <Column half>
            <Content paragraph>
              <Heading html="h6" level="sh" gray strong>
                {block.title}
              </Heading>
              <Heading html="h2" level="h2">
                {block.heading}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.content,
                }}
              />
              <ContentButton to="/feature/billing" primary border sm>
                Learn About Billing
              </ContentButton>
            </Content>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default Billing

export const blockBilling = graphql`
  fragment BlockBilling on DatoCmsBlockBilling {
    id
    title
    heading
    content
    model {
      apiKey
    }
  }
`

const ContentButton = styled(Button)`
  align-self: flex-start;
  margin-top: var(--spacing--sm);
`

const ColumnImage = styled(Column)`
  background: linear-gradient(322.93deg, #d4ebe9 19.79%, #eef8f8 85.94%);
  border-radius: 4px;
`
